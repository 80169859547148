import React, {useEffect, useState} from "react";
import {FilterList, FilterListOff} from "@mui/icons-material";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField,} from "@mui/material";
import * as dateFns from 'date-fns';
import useDateRange from "../../commons/useDateRange";
import {DateRangePicker} from "../user/orders/ordersFilters";

const SearchInput = ({search, setSearch}) => (
  <TextField
    fullWidth
    label="Wyszukaj (w nagłówkach, uri, parmetrach żądania, ciele odpowiedzi)"
    variant="outlined"
    value={search}
    onChange={e => setSearch(e.target.value)}
    size="small"
  />
);

const statuses = [
  '2xx',
  '3xx',
  '4xx',
  '5xx',
];

const StatusSelect = ({status, setStatus}) => (
  <FormControl sx={{minWidth: 120, width: '100%'}}>
    <InputLabel size="small" id="status-select-helper-label">Status</InputLabel>
    <Select
      fullWidth
      labelId="status-select-helper-label"
      id="status-select"
      value={status}
      label="Status"
      onChange={(e) => setStatus(e.target.value)}
      size="small"
    >
      <MenuItem value="">
        <em>Dowolny</em>
      </MenuItem>
      {statuses.map((name) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
    </Select>
  </FormControl>
);


const ApiRequestsFilters = ({render}) => {
  const created = useDateRange();
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [params, setParams] = useState({});
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);

  const handleClear = () => {
    created.handleClear();
    setStatus('');
    setSearch('');
    setFiltersActive(false);
  }

  useEffect(() => {
    const updatedParams = Object.entries({search}).reduce((acc, [key, value]) => {
      if (value) {
        return {...acc, [key]: value};
      }
      return acc;
    }, {});

    if (created.params.exact) {
      updatedParams.created = dateFns.format(created.params.exact, 'yyyy-MM-dd');
    }
    if (created.params.from && created.params.to) {
      updatedParams.createdBetween = [
        dateFns.format(created.params.from, 'yyyy-MM-dd'),
        dateFns.format(created.params.to, 'yyyy-MM-dd')
      ];
    }

    if (status.length) {
      updatedParams.response_status = status;
    }

    if (Object.keys(updatedParams).length) {
      setFiltersActive(true);
    }

    setParams(updatedParams);
  }, [created.params, status, search]);

  return (
    <>
      <Grid container spacing={2} mb={6}>
        <Grid item xs={4} md={6}>
          <SearchInput search={search} setSearch={setSearch}/>
        </Grid>
        <Grid item xs={2}>
          <StatusSelect status={status} setStatus={setStatus}/>
        </Grid>

        <Grid container item xs={6} md={4} justifyContent='flex-end'>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              disableElevation
              onClick={() => setAdvancedOpen(!advancedOpen)}
              endIcon={<FilterList/>}
            >
              {advancedOpen ? 'Ukryj zaawansowane' : 'Zaawansowane'}
            </Button>
            <Button
              variant="outlined"
              disabled={!filtersActive}
              disableElevation
              onClick={handleClear}
              endIcon={<FilterListOff/>}
            >
              Wyczyść
            </Button>
          </Stack>
        </Grid>
        {advancedOpen && (
          <Grid container item xs={12}>
            <Grid container spacing={2} justifyContent='flex-end'>
              <Grid container item xs={12} lg={6}>
                <DateRangePicker
                  date={created}
                  name="created"
                  label="Data wywołania"
                  fromParams={{maxDate: new Date()}}
                  exactParams={{maxDate: new Date()}}/>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {render && render(params)}
    </>
  );
}
export default ApiRequestsFilters;
