import React from 'react';
import {connect} from 'react-redux';
import Layout from '../../templates/layout';
import BasePageContent from '../../components/utils/BasePageContent';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import Paginator from "../../components/Paginator";
import StyledTableCell from "../../components/utils/StyledTableCell";
import BaseLoader from "../../components/utils/BaseLoader";
import {Alert, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@mui/material';
import {Link} from 'gatsby';
import ApiRequestsFilters from "../../components/apiRequests/ApiRequestsFilters";

const mapStateToProps = s => ({apiRequests: s.findAPIRequests});

const headerColumns = [
  'Status odpowiedzi',
  'Metoda HTTP',
  'URI',
  'Adres IP',
  'Czas przetwarzania',
  'Wywołano',
]

const RequestRow = ({request}) => (
  <TableRow
    hover
    key={request.code}
    component={Link}
    to={request.code}
    sx={{cursor: 'pointer'}}
  >
    <TableCell>{request.responseStatus}</TableCell>
    <TableCell>{request.httpMethod}</TableCell>
    <TableCell>{new URL(request.url).pathname}</TableCell>
    <TableCell>{request.remoteIp}</TableCell>
    <TableCell>{request.processingTime} ms</TableCell>
    <TableCell>{request.createdAt}</TableCell>
  </TableRow>)

const ApiRequestsList = ({actions, apiRequests}) => (
  <Layout>
    <BasePageContent title="Żądania API">
      <ApiRequestsFilters
        actions={actions}
        render={(filterParams) => (
          <Paginator
            resource={apiRequests}
            params={{filter: filterParams}}
            getResource={actions.findAPIRequests}
            render={(apiRequests, paginationFooter, loading, error) => (<>
              {error && <Alert severity='error'>{error}</Alert>}
              <BaseLoader loading={loading}/>
              <TableContainer>
                {apiRequests && (<Table>
                  <TableHead>
                    <TableRow>{headerColumns.map(key => <StyledTableCell>{key}</StyledTableCell>)}</TableRow>
                  </TableHead>
                  <TableBody>
                    {apiRequests.length
                      ? apiRequests.map(req => <RequestRow request={req}/>)
                      : (<TableRow>
                        <TableCell colSpan={headerColumns.length}>
                          <Alert severity='info' sx={{my: 2}}>Brak pozycji spełniających kryteria wyszukiwania</Alert>
                        </TableCell>
                      </TableRow>)
                    }
                  </TableBody>
                </Table>)}
                <Grid container justifyContent='center'>
                  <Grid item>
                    <Box py={2}>{paginationFooter()}</Box>
                  </Grid>
                </Grid>
              </TableContainer>
            </>)}
          />
        )}
      />
    </BasePageContent>
  </Layout>
);

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(ApiRequestsList);
